<template>
  <component
    :is="componentName"
    :component-data="componentData"
    :key="index"
    @updated="$emit('updated')"
  />
</template>

<script>
import Unknown from './TypeUnknown.vue'

export default {
  components: {
    "lesson-type-1": () => import('./TypeSpeaking/DeepCatchUp.vue'),
    "lesson-type-2": () => import('./TypeListening/TopicCard.vue'),
    "lesson-type-3": () => import('./TypeVocab.vue'),
    "lesson-type-4": () => import('./TypeWordconnect.vue'),
    "lesson-type-5": () => import('./TypePictureWord.vue'),
    "lesson-type-6": () => import('./TypeWordPronunciation.vue'),
    "unknown-type": Unknown,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      index: 1
    }
  },
  computed: {
    componentName() {
      let name = `lesson-type-` + this.componentData.type;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(name) ? name : "unknown-type";
    },
  },
  watch: {
    'componentData.content'() {
      this.index++
    }
  },
};
</script>
